<template>
  <div id="QuestionAnswer">
    <div class="mainTop">
      <div>
        <p class="second">{{quizDetail.quiz.question}}</p>
        <p class="third">{{quizDetail.question_describe}}</p>
        <div class="forth">
          <div><img :src="quizDetail.user.headimgurl ?Global.changeImg(quizDetail.user.headimgurl) :'../../assets/image/home/image1.png'" /><span>{{quizDetail.member_info.name}}</span></div>
          <div>
            <!-- <span><span>{{quizDetail.answer}}</span>回答</span> -->
            <!-- <span><span>{{quizDetail.circusee_count}}</span>围观</span> -->
          </div>
        </div>
      </div>
    </div>
    <div >
      <van-field
        v-model="questionDescribe"
        rows="5"
        autosize
        type="textarea"
        placeholder="请输入您的回答..."
      />
    </div>
    <div class="toSubmit" @click="toSub">提交回答</div>
  </div>
</template>
<script>
export default {
  name: 'QuestionAnswer',
   data(){
    return{
      questionDescribe:'',
      getTags:'',
      quizDetail:'',
      id:'',//问题id
    }
  },
  mounted(){
    this.id = this.$route.query.id
    // 问题
    this.axios({
      method: 'GET',
      url: '/quiz/notAuditAnswerDetail?quizId='+this.id,
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.quizDetail = res.data.data
        this.questionDescribe = this.quizDetail.answer
      }
    })
  },
  methods:{
    toSub(){
      if(!this.questionDescribe){
        this.$toast('请输入回答')
        return
      }
      this.axios({
        method: 'POST',
        url: '/quiz/userAnswerQuiz/'+this.quizDetail.quiz_id,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        data:{
          answer:this.questionDescribe,
        }
      }).then((res) => {
        if(res.data.code == 0){
          this.$toast('回答成功,待审核')
          var _this = this;
          setTimeout(function(){
            _this.$router.go(-1)
          },2000)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
#QuestionAnswer{
  >div{
    background: #23252E;
  }
  .mainTop{
    padding: 0.15rem;
    margin-bottom: 0.11rem;
    >div{
      .fifth{
        background: #3D404F;
        border-radius: 0.02rem;
        padding: 0.11rem 0.2rem;
        display: flex;
        justify-content: flex-start;
        margin: 0.15rem 0 0.12rem;
        >div{
          >p:last-child{
            color: #989AB1;
            font-size: 0.12rem;
          }
          >p:first-child{
            color: #F6F7FD;
            font-size: 0.14rem;
            margin-bottom: 0.05rem;
          }
        }
        >img{
          width: 0.3rem;
          margin-right: 0.16rem;
        }
      }
      .forth{
        >div:last-child{
          display: flex;
          >span{
            margin-left: 0.2rem;
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.12rem;
            margin-left: 0.2rem;
            >span{
              color: #F6F7FD;
              font-size: 0.12rem;
              margin-right: 0.05rem;
            }
          }
        }
        >div:first-child{
          color: #989AB1;
          display: flex;
          align-items: center;
          >span{
            font-size: 0.12rem;
            margin: 0 0.1rem 0 0.08rem;
          }
          >img{
            width: 0.19rem;
            border-radius: 50%;
          }
        }
      }
      .third{
        color: #989AB1;
        font-size: 0.12rem;
        line-height: 0.17rem;
        margin-bottom: 0.15rem;
      }
      .second{
        color: #F6F7FD;
        font-size: 0.14rem;
        margin: 0 0 0.12rem;
      }
      .first{
        >div{
          color: #989AB1;
          font-size: 0.14rem;
          display: flex;
          align-items: center;
          >span{
            margin: 0 0.1rem 0 0.08rem;
          }
          >img{
            width: 0.27rem;
          }
        }
        >span{
          color: #727387;
          font-size: 0.12rem;
        }
      }
      >div{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .toSubmit{
    height: 0.42rem;
    background: #3890FF;
    opacity: 1;
    border-radius: 5px;
    color: #F6F7FD;
    line-height: 0.42rem;
    margin: 0.2rem 0.15rem;
    text-align: center;
  }
 
}
</style>